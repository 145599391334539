$grey: #ced4da;
$white: #ffffff;
$warning: #dc3545;
$success : green;

$color_1: #1E263E; //=> Blue
$color_2: #252951; //=> Dark-Blue
$color_3: #1C2640; //=> Very-Dark-Blue
$color_4: #FFFFFF; //=> White
$color_5: #F0F0F7; //=> Light-Gray
$color_6: #357FFA; //=> Primary
$color_7: #1E263E;
$color_8: #4D77BB; //=> table header color

$color_9: #27283f; //> main color

$color_10: #f3f7fb; //> page background
$color_11: #b3b3b3; //> table header
$color_12: #eb9f40; //> grey one
// -------------------------------
// $base-font: 'Roboto';
$base-font: 'Spartan';
$base-font-color: #000000;

$heading_1: #1E263E;
$subheading_1: #404D5A;

@import './app/themes/style/variable';

mat-divider.mat-divider.mat-divider-horizontal {
    margin: 0px 0px 10px 0px;
}

.container-page {
    background: white;
    box-shadow: 2px 2px 12px #ccc;
    border-radius: 20px;
    padding: 1rem;
    min-width: calc(100% - 2rem);
    min-height: calc(100% - 2rem);
    margin-bottom: 20px;
}

.page-header {
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    margin-bottom: 8px;

    h1 {
        margin: 0px;
        font-size: 20px;
    }

    .back-button {
        height: 30px;
    }
}

.form-container {
    padding-top: 10px;
}

.action-btn-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.m-10 {
    margin: 10px;
}

button.back-button.mdc-button {
    border: 2px solid $color_9 !important;
    color: $color_9 !important;
    display: inline-block;
    background: transparent !important;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
    border-bottom-width :0 !important;
    border-bottom-style:none !important;
}

// ------------ PDF Page Style start -----------------------
#coverBody .center2 {
    margin-top: 21mm;
    text-align: center;
}

#coverLogo {
    height: 5mm;
    margin: auto;
}

#coverTitle,
#coverSubTitle {
    font-weight: 400;
    color: grey;
    font-size: 12px;
    margin-bottom: 2px;
    letter-spacing: 0px;
}

#coverSubTitle {
    font-weight: 200;
    font-size: 5px;letter-spacing: 0px;
}

// ------------ PDF Page Style End -----------------------




// @import './variable';

@font-face {
    font-family: "GothamBook";
    src: url(./assets/fonts/Gotham/GothamBook.ttf) format("truetype");
}

@font-face {
    font-family: "GothamLight";
    src: url(./assets/fonts/Gotham/GothamBook.ttf) format("truetype");
}

@font-face {
    font-family: "Spartan";
    src: url(./assets/fonts/Spartan/Spartan-VariableFont_wght.ttf) format("truetype");
    font-weight: 100 1000;
    font-stretch: 25% 151%;
}

@font-face {
    font-family: "SpartanThin";
    src: url(./assets/fonts/Spartan/static/Spartan-Thin.ttf) format("truetype");
}

@font-face {
    font-family: "SpartanMedium";
    src: url(./assets/fonts/Spartan/static/Spartan-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "SpartanRegular", sans-serif;
    src: url(./assets/fonts/Spartan/static/Spartan-Regular.ttf) format("truetype");
}

body {
    margin: 0;
    font-family: $base-font, sans-serif;
    font-size: 1rem;
    color: $base-font-color;

}

html,
body {
    height: 100%;

}

.form {
    padding: 10px;

}

.my-spacer {
    flex: 1 1 auto
}

.lable-txt,
span,
p,
h1,
h2,
h3,
h4,
h5,
div {
    font-family: $base-font, sans-serif;
}


//   --------------Snack-Bar style end --------------
.error-snack {
    div {
        background-color: #fd4c62 !important;
        border-radius: 0 !important;
    }

    button {
        color: #950505 !important;
        background-color: #e9300e !important;
    }

    margin: 0 !important;
}

.success-snack {
    div {
        background-color: #b769fb !important;
        border-radius: 0 !important;
    }

    button {
        color: #090b4e !important;
        background-color: #900ee9 !important;
    }

    margin: 0 !important;
}


.error-snack .mat-simple-snackbar,
.success-snack .mat-simple-snackbar {
    justify-content: center !important;
    font-size: 12px !important;

}

mat-form-field {
    width: 100%;
    margin-top: 10px;
}

.form-group {
    margin: 5px;
}

.invalid-feedback {
    width: 100%;
    font-size: 9px;
    color: red;
    margin-top: -15px;
    height: 22px !important;

}

.mat-mdc-form-field-subscript-wrapper {
    height: 20px ;
}
.filter-box .mat-mdc-form-field-subscript-wrapper {
    height: 0px;
    padding-top: 0px;
}
.valid-feedback {
    width: 100%;
    font-size: 80%;
    color: $success;
    margin-top: -21px;
}

.invalid-feedback-terms {
    width: 100%;
    font-size: 10px;
    color: red;
    margin-top: 0px;
    height: 10px !important;

}

.center {
    display: flex;
    justify-content: center;

}

.cursor {
    cursor: pointer;
}

.mat-mdc-menu-item.mdc-list-item {
    .mdc-list-item__primary-text {
        color: $color_4;
    }
}

.my-sidenav .mat-mdc-list-item.mdc-list-item.menu-list-item .nav-item .lable-txt {
    color: #cacaca;
}

.my-sidenav .mat-mdc-list-item.mdc-list-item.menu-list-item.active .nav-item .lable-txt {
    color: #fff;
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
    background-color: $color_9;
}

.mat-mdc-menu-content .mat-mdc-menu-item mat-icon {
    color: $color_4;
}

.form-group-btn {
    display: flex;
    justify-content: space-between;

    button {
        margin: 5px;
    }
}

button.reset-btn.mdc-button {
    margin-left: 10px !important;
}

.form-group-btn {
    margin: 0px 1%;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #ccc #FFFFFF;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #FFFFFF;
}

*::-webkit-scrollbar-thumb {
    background-color: $color_9;
    border-radius: 20px;
    border: 3px solid #FFFFFF;
}

// .mat-mdc-tab-header .mdc-tab {
//     font-size: 23px;
// }

// .mat-mdc-tab.mdc-tab {
//     margin: 20px;
//     background-color: cyan;
//     color: #FFFFFF;
//     margin-top: -8px;
// }


/* Register login forget password page css */
.auth-page-title {
    color: $heading_1 !important;
    font-size: 30px !important;
    line-height: 38px !important;
    font-family: "SpartanRegular", sans-serif !important;
    margin: 0px !important;    font-weight: 600 !important;
}

.auth-page-para {
    color: $subheading_1;
    font-size: 14px;
    line-height: 20px;
    font-family: "SpartanRegular", sans-serif;
    margin: 0px;
}
.auth-pages .mat-divider {
    border-top-color: #fff;    margin: 0px 0px 10px 0px !important;
}
label.form-check-label,
.center.login_link,
.center.Register_link,
.back_to_login {
    color: $subheading_1;
    font-size: 14px;
    line-height: 20px;
    font-family: "SpartanRegular", sans-serif;
    margin: 0px;
}

mat-label {
    color: $color_7;
    font-size: 12px;
    line-height: 20px;
    font-family: "SpartanMedium";
    text-transform: capitalize;
}
button.create-new-role {
    color: #fff !important;
}
.mat-drawer-content .page-header h1 span {
    font-weight: 500 !important;
}

.auth-pages .mat-mdc-text-field-wrapper {
    border: 1px solid #404D5A;
    height: 35px !important;
    border-radius: 5px !important;
    padding: 5px 10px !important;
    font-size: 16px;
    line-height: 28px;
}


.mat-mdc-form-field-infix, span.mat-mdc-select-min-line {
    font-size: 12px;
}

label.form-check-label a,
.center.login_link a,
.center.Register_link a,
.back_to_login a, .auth-pages a {
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    font-family: "SpartanMedium";
    color: $color_7;
    font-weight: 600;
}

span.mat-mdc-button-touch-target {
    height: auto !important;
}

.app-footer span.mdc-button__label,
.app-footer span {
    font-size: 12px;
    line-height: 18px;
    color: #404D5A !important;
}

.form-group button.mdc-button {
    background: $color_7 !important;
    width: 100%;
    height: 35px;
    border-radius: 5px;
    margin-top: 10px;
}

.mat-mdc-form-field-subscript-wrapper {
    padding-top: 4px;
}

.app-footer .mat-mdc-button {
    --mat-mdc-button-persistent-ripple-color: transparent;
    --mat-mdc-button-ripple-color: transparent;
}

// .form .mat-mdc-icon-button.mat-mdc-button-base {
//   top: -15px;
// }
mat-divider.mat-divider.mat-divider-horizontal {
    margin: 10px 0px 10px 0px;
}

.card-right {
    position: relative;
    display: flex;
    align-items: center;
}

.card-right .right-colum {
    width: 100%;
}

.app-footer {
    position: absolute;
    bottom: 0px;
    padding-top: 60px;
}

.form-group {
    margin: 0px;
}

.form form {
    margin-top: 20px;
}

span.copyright-1 {
    padding-right: 10px;
}

.form .cursor {
    color: $color_9;
    font-size: 14px;
    line-height: 26px;
    font-family: "SpartanMedium";
}

.btn-group button {
    margin: 0px 10px;
}

.info-container  {
    width: 31.77%;
    padding: 2px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.info-value {
    background: $color_11;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px !important;
}

.action-button {
    margin: 5px !important;
    padding: 10px !important;
}

//mat chips styles
.chips-section {
    width: 65%;
    margin-left: 1%;
}

.chips-label {
    height: 100px;
}

.chips-grid {
    min-height: 100px;
    margin-top: 30px;
}
//mat chips styles

//cards
.cards-section {
    display: flex;
}

.card-item {
    margin: 10px;
    padding: 10px;
    width: 200px;
}

.notify-message-card {
    text-align: center;
    color: red;
    font-weight: bold;
    margin: 10px;
}
//cards


a:hover {
    cursor: pointer;
}

//reports
.reports-section {
    display: flex;
    flex-wrap: wrap;
}

.report-button {
    margin: 10px;
    font-size: 12px !important;
    font-weight: bold;
}

.mdc-tab {
    font-size: 12px;
}

//reports

// .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
// 	padding-top: 15px !important;
// 	padding-bottom: 10px !important;
// }

// .mat-mdc-form-field-infix {
// 	min-height: 24px;
// }

@media only screen and (max-width: 767px) {
    .card-container {
        flex-direction: column !important;
    }

    .card-right {
        width: 100% !important;
        padding-top: 40px;
        background: #fff;
    }

    .card-left {
        display: flex !important;
        width: 100% !important;
        height: 300px !important;

    }

    .drive-logo-left {
        position: unset !important;
        transform: unset !important;
        width: 200px !important;
        padding: 20px;
        margin: 0px auto !important;
        display: block;
    }

    .app-footer {
        position: unset !important;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
    }

    .my-button-row {
        text-align: center;
        margin-bottom: 10px;
    }

    .my-button-row button {
        padding: 0px 4px;
        line-height: 14px !important;
        height: 23px !important;
    }

    .auth-page-title {
        font-size: 25px;
        line-height: 34px;
    }

    .right-colum .main-content {
        padding: 0px 10px !important;
        margin: 0px 0px !important;
    }

    .info-container  {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .card-container {
        height: unset !important;
    }

    .app-footer {
        position: unset !important;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
    }

    .my-button-row {
        text-align: center;
        margin-bottom: 10px;
    }

    .my-button-row button {
        padding: 0px 4px;
        line-height: 14px !important;
        height: 23px !important;
    }

    .auth-page-title {
        font-size: 23px;
        line-height: 28px;
    }

    .info-container  {
        width: 46%;
    }
}

/* End Register login forget password page css */

/* Sidebar After login css */
mat-sidenav {
    background: $color_7;
}

/* End Sidebar After login css */

/* Header After login css */
.mat-toolbar-row,
.mat-toolbar-single-row {
    padding: 0 0px !important;
}

.mat-toolbar.mat-primary {
    background: $color_7;
}

.mat-toolbar.mat-primary {
    background: $color_7 !important;
    border-bottom: 0px !important;
}

/* End Header After login css */


/* table css */
// .mat-mdc-table .mdc-data-table__header-row {
//     height: 44px;
// }

.mat-mdc-table .mdc-data-table__header-row th {
    background: $color_11;
    color: #fff;
    font-size: 12px;
    font-family: "SpartanMedium";
    text-transform: capitalize;
    line-height: 15px;
    font-weight: bold;
    color: black;
}

// .mat-mdc-table {
//     margin: 20px 0px;
// }

.mdc-data-table__cell a {
    color: #2315c7;
    text-decoration: none;
}

.mat-sort-header-arrow {
    color: #fff !important;
}

/* End table css */


/* Resource section dashboard*/
.form-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mat-horizontal-content-container .form-container {
    padding-top: 30px;
}

.mat-horizontal-content-container .form-container .form-group {
    padding-bottom: 10px !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
    flex-direction: row !important;
    justify-content: space-evenly;
    flex: auto;

}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
    background: transparent;
    padding: 0px !important;
    margin-left: -15px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
    padding: 0px !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
    background: #F0F0F7;
    padding: 0px 0px;
    border-radius: 50px;

}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
    align-items: center !important;
    justify-content: space-between;
    margin: 20px auto;
}

.mat-step-header .mat-step-icon-selected {
    background-color: #3E6198 !important;
    color: white;
    height: 40px !important;
    width: 40px !important;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
    display: none;
}

.mat-stepper-horizontal-line {
    border-top: 0px !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before {
    content: none !important;
}

mat-step-header[ng-reflect-active="true"] {
    background: #4D77BB !important;
    color: #fff !important;
    border-radius: 50px 0px 0px 50px !important;
    width: auto;
}

mat-step-header[ng-reflect-selected="false"] {
    background: #F0F0F7 !important;
    color: $color_7 !important;
    border-radius: 50px 0px 0px 50px !important;
}

mat-step-header[ng-reflect-selected="false"] .mat-step-text-label {
    color: $color_7;
}

.mat-horizontal-stepper-header .mat-step-icon {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.mat-step-header .mat-step-label.mat-step-label-active {
    color: #fff;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
    padding: 0px 35px 0px 10px !important;
    font-size: 12px;
    color: $color_7 !important;
    font-family: "SpartanMedium" !important;
    text-align: left !important;
    font-weight: 500;
}

.mat-step-header .mat-step-label.mat-step-label-active {
    color: #fff !important;
}

.mat-step-icon.mat-step-icon-state-number {
    height: 40px !important;
    width: 40px !important;
}

.mat-step-icon.mat-step-icon-state-edit {
    height: 40px !important;
    width: 40px !important;
}

.mat-step-header .mat-step-icon {
    background-color: #fff !important;
    color: #357FFA !important;
}

.mat-step-header .mat-step-icon-selected {
    background-color: #3E6198 !important;
    color: #fff !important;
}

.mat-horizontal-stepper-header-container {
    padding: 0px 0px 0px 10px !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
    font-size: 13px !important;
    line-height: 18px !important;
}

.file-field {
    border: 1.4px solid gray !important;
    border-radius: 5px !important;
    padding: 3px 10px !important;
    font-size: 14px;
    line-height: 28px;
}

::-webkit-input-placeholder {
    /* Edge */
    font-size: 14px;
    line-height: 28px;
    text-transform: capitalize;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 14px;
    line-height: 28px; text-transform: capitalize;
}

::placeholder {
    font-size: 14px;
    line-height: 28px; text-transform: capitalize;
}

.mat-mdc-option {
    min-height: auto !important;
    padding: 6px 10px !important;
}

.page-header .back-button {
    height: 36px !important;
    color: #fff !important;
}

.mat-horizontal-stepper-content .form-group {
    // flex: calc(33.33% - 6px);
    width: 48% !important;
    margin: 0px 1% !important;
}
.form-container.maintence-form-truck .form-group {
    width: 31.33% !important;
    margin: 0px 1% !important;
}
.mat-horizontal-stepper-content .form-group-btn {
    display: block !important;
}

.mat-drawer-content .page-header h1 {
    font-family: "SpartanRegular", sans-serif;
    color: $color_9;
    font-weight: 600;
}

.mat-warn.mat-mdc-button-base {
    border: 1px solid $color_7;
    color: $color_7 !important;
    display: inline-block;
    background: transparent !important;
    height: 36px !important;
}

.mat-primary.mat-mdc-button-base {
    background: $color_9 !important;
    font-weight:bold;
    // border: 1px solid $color_9;
    // display: inline-block;
}

button.mdc-button.mdc-button--raised.mat-mdc-raised-button.mat-warn.mat-mdc-button-base {
    border: 1px solid$color_7;
    color: $color_7 !important;
    display: inline-block;
    background: transparent !important;
}

.mat-horizontal-content-container {
    padding: 0px !important;
}

.app-pages.mat-sidenav-content.mat-drawer-content h1 {
    margin-top: 0px;
}

.my-sidenav .child-menu {
    margin-top: -6px;
    margin-bottom: 6px;
}

.download-btns {
    margin-right: 10px;
}

@media (max-width: 767px) {
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
        flex-direction: column;
        background: transparent;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
        margin-left: 0px;
        width: 100%;
        border-radius: 50px !important;
        margin-bottom: 11px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

}

@media (max-width: 991px) {
    .card-container {
        flex-direction: column;
    }

    .layout-content .form-group {
        width: 100% !important;
        margin-right: 0px !important;
        flex: 100% !important;

    }

    .mat-horizontal-stepper-content .form-group {
        width: 100% !important;
        margin-right: 0px !important;
        flex: 100% !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .layout-content .form-group {
        width: 48% !important;
        margin: 0px 1% !important;
    }

    mat-label {
        font-size: 12px;
        line-height: 24px;
    }

    .mat-horizontal-stepper-content .form-group {
        width: 48% !important;
        margin: 0px 1% !important;
    }
}

/* End Resource section dashboard*/

/* dashboard sidebar menu */
.my-sidenav mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item {
    height: 2.2vw !important;
}

.file-hint {
    display: flex;
    justify-content: flex-start;
}

.file-hint p {
    padding-right: 25px;
    padding-top: 3px;
}

/* End dashboard sidebar menu */

/* Header css */
button.my-icon.menu-toggle mat-icon {
    color: #000;
}

.mat-toolbar.mat-primary {
    background: #fff !important;
    border-bottom: 0px !important;
}

// .menu-list-item mat-icon {
//     margin-left: 0px !important;
// }

// .my-sidenav .mat-mdc-list-base {
//     margin: 10px 0px 0px 10px;
// }

.mat-toolbar.mat-primary {
    color: $color_9 !important;
    // padding: 0px 28px 0px 20px !important;
}

.Mail-section {
    padding-right: 40px;
}

.static-section {
    padding: 0px 40px;
}

.Home-section {
    padding-right: 40px;
}

.language-change {
    padding-right: 40px;
}

.Home-section mat-icon,
.Mail-section mat-icon {
    color: #9C9CA8;
}

.mdc-button.language-change {
    width: auto !important;
    height: auto !important;
    min-width: auto !important;
    padding: 0px 40px 0px 0px !important
}

.Home-section .mat-badge-content,
.Mail-section .mat-badge-content {
    width: 18px !important;
    height: 18px !important;
    line-height: 18px !important;
    font-size: 9px;
    font-family: "SpartanRegular", sans-serif;
    right: -3px !important;
    top: -3px !important;
}

.Home-section img,
.Mail-section img {
    height: 20px !important;
}

.profile-menu button:hover {
    color: #fff !important;
}

.profile-menu .mdc-list-item {
    min-height: auto;
    padding: 8px;
    color: #fff !important;
}

.profile-menu .mdc-list-item span {
    font-size: 12px !important;
    line-height: 14px !important;
}

.profile-menu .mdc-list-item mat-icon {
    font-size: 16px !important;
    line-height: 15px !important;
    margin-right: 6px;
    height: 16px !important;
    width: 16px !important;
}

.mat-toolbar.mat-primary .Mail-section {
    margin-top: 8px;
}
.mat-toolbar.mat-primary .static-section {
    margin-top: 8px;
}
.mat-toolbar.mat-primary .Home-section {
    margin-top: 8px;
}
.mat-mdc-icon-button.mat-mdc-button-base.profile-menu {
    margin-top: 5px !important;
}
.form-group.search-form {
    margin-top: 18px;
    position: relative;
}

img.search-icon {
    position: absolute;
    right: 2px;
    height: 18px;
    top: 2px;
}

.form-group.search-form .mat-mdc-text-field-wrapper {
    background: #ffffff;
    border: 1px solid#ffffff !important;
    width: 260px !important;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 5px !important;
    height: 32px;
    line-height: 20px;
}

.form-group.search-form .mdc-line-ripple::before {
    border-bottom: 0px !important;
}

.language-change {
    padding-right: 40px;
    padding-left: 0px;
}

.logo-box1 {
    display: flex;
    align-items: center;
    margin: 2vw;
}

.layout-header-logo {
    padding-left: 17px;
}

.toggle666 {
    height: 18px;
}

/*
.app-pages.mat-sidenav-content.mat-drawer-content {
    margin-left: 16% !important;
    width: 84% !important;
}


.my-sidenav.mat-drawer-opened {
    width: 16% !important;
    margin-left: 0% !important;
}

.mat-drawer.mat-drawer-side {
    display: block !important;
    width: 4% !important;
    margin-left: 4% !important;
    visibility: visible !important;
    overflow-x: hidden;
}


mat-toolbar.mat-toolbar {
    position: fixed;
    z-index: 9999;
    width: 100%;
}

.mat-toolbar.mat-primary {
    width: 84%;
}


.my-sidenav {
    width: 20% !important;
}

.my-sidenav {
    padding-left: 0px;
}

section.layout-content {
    background: #f0f0f7;
    padding: 75px 15px 30px !important;
}
 */

 .mat-drawer.mat-drawer-side {
    display: block !important;
    width: 4% !important;
    margin-left: 4% !important;
    visibility: visible !important;
    overflow-x: hidden;
}

.my-sidenav.mat-drawer-opened {
    width: 20vw !important;
    margin-left: 0% !important;
}

.my-sidenav-container {
    display: flex;
}

.mat-sidenav-content.app-pages.content-full {
    width: 100% !important;
    margin-left: 0px !important;
}

// .mat-toolbar.mat-primary.topbar-fixed {
//     width: 100%;
// }

.layout-header-logo img {
    width: 8vw;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
    height: 50px !important;
}

mat-toolbar.mat-toolbar.my-toolbar .menu-toggle-toolbar {
    display: none;
}

// mat-toolbar.mat-toolbar.my-toolbar.topbar-fixed .menu-toggle-toolbar {
//     display: block;
// }

// .menu-list-item mat-icon {
//     margin-right: 0px !important;
//     line-height: 17px;
// }

mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item .nav-item .lable-txt {
    font-weight: 580;
    // font-size: clamp(.8rem, 0.8vw, .8rem) !important;
    display: flex;
    font-size: .85vw; /* Ensures ~40 characters fit inside */
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden; 
}

.mat-horizontal-stepper-content .form-group-btn .back-button,
.form-group-btn .back-button {
    border: 1px solid $color_9 !important;
    color: $color_9 !important;
    display: inline-block;
    background: transparent !important;
}

.mat-horizontal-stepper-content .form-group-btn,
.form-group-btn {
    padding-top: 20px;
}

button.reset-btn1.mdc-button {
    margin-left: 10px !important;
    font-weight: bold;
    border: 2px solid $color_9 !important;
}

.mat-horizontal-stepper-header-container:after {
    content: url("./assets/images/icon-last.png");
    background: #fafafa !important;
    height: 40px !important;
    width: 40px !important;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 50px;
    line-height: 44px !important;
    text-align: center;
    margin-left: -22px;
    z-index: 2;
}

@media only screen and (max-width: 600px) {
    .app-pages.mat-sidenav-content.mat-drawer-content {
        margin-left: 0% !important;
        width: 100% !important;
    }

    mat-sidenav-container {
        margin-top: 0px !important;
    }

    .my-sidenav {
        width: 260px !important;
    }

    mat-toolbar.mat-toolbar.my-toolbar .menu-toggle-toolbar {
        display: block !important;
    }

    mat-toolbar.mat-toolbar.my-toolbar.mat-primary .form-group.search-form {
        display: none;
    }

    .Home-section,
    .Mail-section,
    .mdc-button.language-change,
    .static-section {
        padding-right: 20px !important;
    }

    .profile-menu .mat-icon {
        height: 24px !important;
        width: 24px !important;
        margin-top: 0px !important;
    }

    .profile-menu .mat-icon img {
        height: 24px !important;
        width: 24px !important;
    }

    section.layout-content {
        background: #f0f0f7;
        padding: 75px 15px 30px !important;
    }

    mat-toolbar.mat-toolbar.my-toolbar.mat-primary {
        flex-wrap: wrap;
        align-items: center;
        width: 100% !important;

    }

    .mat-toolbar.mat-primary {
        color: $color_9 !important;
        padding: 0px 20px 0px 20px !important;
    }

}
@media only screen and (min-width: 601px){
.mat-toolbar.mat-primary {
    background: #ffffff !important;

}
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
    /*
    .my-sidenav {
        width: 40% !important;
    }

    .app-pages.mat-sidenav-content.mat-drawer-content {
        margin-left: 40% !important;
        width: 60% !important;
    }

    .mat-toolbar.mat-primary {
        width: 60%;
    }

    .mat-sidenav-content.app-pages.content-full {
        width: 100% !important;
        margin-left: 0px !important;
    }
    */

    .form-group.search-form .mat-mdc-text-field-wrapper {
        width: 190px !important;
    }

    .Home-section,
    .Mail-section,
    .mdc-button.language-change,
    .static-section {
        padding-right: 20px !important;
    }

    .profile-menu .mat-icon {
        height: 24px !important;
        width: 24px !important;
        margin-top: 0px !important;
    }

    .profile-menu .mat-icon img {
        height: 24px !important;
        width: 24px !important;
    }

    .form-group.search-form {
        display: none;
    }
    .mat-mdc-paginator-container{
        flex-wrap: unset !important;
    }
    .mat-mdc-paginator-range-actions {
        width: auto !important;
    }
    .mat-mdc-paginator-range-label {
        margin: 0 2px 0 2px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    /*
    .my-sidenav {
        width: 34% !important;
    }

    .app-pages.mat-sidenav-content.mat-drawer-content {
        margin-left: 34% !important;
        width: 66% !important;
    }

    .mat-toolbar.mat-primary {
        width: 66%;
    }

    .mat-sidenav-content.app-pages.content-full {
        width: 100% !important;
        margin-left: 0px !important;
    }
    */

    .form-group.search-form .mat-mdc-text-field-wrapper {

        width: 190px !important;
    }

    .Home-section,
    .Mail-section,
    .mdc-button.language-change,
    .static-section {
        padding-right: 20px !important;
    }

    .mat-mdc-icon-button.mat-mdc-button-base.profile-menu {
        width: 30px !important;
        height: 30px !important;
    }

    .profile-menu .mat-icon {
        height: 24px !important;
        width: 24px !important;
        margin-top: 0px !important;
    }

    .profile-menu .mat-icon img {
        height: 24px !important;
        width: 24px !important;
    }
}

/*
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    
    .my-sidenav {
        width: 27% !important;
    }

    .app-pages.mat-sidenav-content.mat-drawer-content {
        margin-left: 27% !important;
        width: 73% !important;
    }

    .mat-toolbar.mat-primary {
        width: 73%;
    }

    .mat-sidenav-content.app-pages.content-full {
        width: 100% !important;
        margin-left: 0px !important;
    }
}
    */

/* End Header css */

mat-accordion mat-expansion-panel-header,
.mat-expansion-panel-header.mat-expanded {
    background: $color_9 !important;
    height: 35px !important;
    color: #fff !important;
}

mat-accordion mat-expansion-panel-header:hover,
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover {
    background: $color_9 !important;
    height: 35px !important;
    color: #fff !important;
}

mat-accordion mat-expansion-panel {
    margin-bottom: 10px !important;
}

mat-accordion mat-expansion-panel-header mat-panel-title {
    color: #fff !important;
    font-family: "SpartanRegular", sans-serif;
    font-size: 14px !important;
}

mat-accordion mat-expansion-panel-header .mat-expansion-indicator::after {
    color: #fff !important;
}

.mat-expansion-panel-content .mat-action-row button.mdc-button.mat-mdc-button.mat-primary {
    color: #fff;
}

.mat-expansion-panel-content .mat-action-row {
    padding: 10px;
}

.mat-expansion-panel-content .form-group {
    width: 45% !important;
    margin: 0px 1% !important;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-left: 3px solid #c5c6c9;
    margin-bottom: 10px !important;
}

.mat-expansion-panel-body {
    padding: 10px 10px 10px !important;
}

.mat-expansion-panel-content .form-group mat-label {
    color: $color_9;
    font-size: 14px;
    line-height: 23px;
    font-family: "SpartanRegular", sans-serif;
}

@media only screen and (max-width: 767px) {
    .mat-expansion-panel-content .form-group {
        flex: 100% !important;
        width: 100% !important;
        margin-right: 0% !important;
        border: 1px solid #ccc;
        padding: 5px 10px;
        border-left: 3px solid #c5c6c9;
        margin-bottom: 10px !important;
        margin-left: 0px !important;
    }

    .mat-expansion-panel-content .form-group {
        display: flex !important;
        flex-direction: column !important;
        flex-wrap: unset !important;
    }
}

/* Start table css */
.table-container {
    overflow-y: auto;
    box-shadow: 5px 5px 5px #9c9e9f;
}

.mat-mdc-paginator-container {
    justify-content: space-between !important;
}

// .mat-mdc-table .mdc-data-table__header-row th,
// .mat-mdc-table td {
//     border: 1px solid#d6d6d65e !important;
// }

svg.mat-mdc-paginator-icon {
    background: white;
    fill: gray;
    box-shadow: 3px 3px 3px #9c9e9f;
}

// table.mat-mdc-table {
//     border: 1px solid rgba(214, 214, 214, 0.368627451);
//     margin-top: 10px;
//     margin-bottom: 10px !important;
// }

.serch-table {
    margin-bottom: 0px !important;
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
    fill: #6960cd!important;
}

.table-action-buttons {
    margin-bottom: 50px;
}

.table-action-buttons > button {
    margin: 0px 5px;
}

// .mat-mdc-table .mdc-data-table__row {
//     height: auto !important;
// }

// table.mat-mdc-table .mat-mdc-checkbox .mdc-checkbox {
//     padding: calc((var(--mdc-checkbox-state-layer-size, 37px) - 18px) / 2) !important;
//     margin: calc((var(--mdc-checkbox-state-layer-size, 32px) - var(--mdc-checkbox-state-layer-size, 40px)) / 2) !important;
// }

// .mat-mdc-table .mdc-data-table__header-row th {
//     white-space: nowrap;
// }

// .mat-mdc-table .mdc-data-table__header-row {
//     height: 40px !important;
// }

// .table-extra {
//     padding: 0px !important;
// }

/* End table css */
/* Start popup box */
.mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 5px !important;
    border-top: 6px solid#4D77BB;
}

.mdc-dialog__content {
    color: #404D5A;
    font-size: 14px;
    line-height: 20px;
    font-family: "SpartanRegular", sans-serif;
}

.mdc-dialog__content h2 {
    font-family: "SpartanMedium";
    color: #404D5A;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}

.mdc-dialog__container table.mat-mdc-table {
    margin-top: 0px !important;
}

.mdc-dialog .mdc-dialog__content {
    padding: 0px 20px !important;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $color_9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $color_9;
}

/* End popup box */
.page-sub-heading {
    margin: 0px !important;
    color: #6D6D6D;
    font-size: 11px;
    line-height: 12px;
    font-family: "SpartanMedium";
    padding-top: 6px;
}

// .mat-mdc-button.mat-mdc-button-base,
// .mat-mdc-raised-button.mat-mdc-button-base,
// .mat-mdc-unelevated-button.mat-mdc-button-base,
// .mat-mdc-outlined-button.mat-mdc-button-base {
//     height: 34px !important;
//     font-size: 10px;
//     padding: 0px 8px;
// }

// .mat-mdc-table .mdc-data-table__header-row {
//     height: 35px !important;
// }
button.bulk-upload.mdc-button {
    margin-right: 10px !important;
    border: 1px solid #4D77BB;
    color: #4D77BB !important;
}

.download-btns button.mdc-button {
    border: 1px solid #4D77BB !important;
    color: #4D77BB !important;
}

button.bulk_delete.mdc-button {
    margin-right: 10px !important;
    border: 1px solid #aeb2b9 !important;
    color: #7c7f85 !important;
    background: rgba(244, 67, 54, 0.1) !important;
}

.excel-pdf-email-link {
    display: flex;
    align-items: center;
}

.excel-pdf-email-link span {
    color: #3A3952;
    font-size: 12px;
    line-height: 14px;
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.excel-pdf-email-link span a {
    color: #3A3952;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.excel-pdf-email-link span img {
    height: 16px;
    width: auto;
    margin-right: 4px;
    margin-top: -2px;
}

.mat-mdc-paginator-container {
    min-height: 40px !important;
}

google-map .map-container {
    height: 360px !important;
}

.map-container {
    margin-bottom: 40px !important;
}

button.generate-order.mdc-button {
    margin-right: 10px !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface button.mdc-button {
    margin-left: 10px !important;
}

.mat-mdc-cell.action-colume {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.mat-expansion-panel-header mat-icon {
    height: 16px;
    width: 16px;
    font-size: 16px;
    margin-right: 4px;
    margin-top: -3px;
}

mat-action-row.mat-action-row button.mdc-button {
    margin-left: 10px !important;
}

@media screen and (max-width: 767px) {
    .page-header {
        flex-wrap: wrap;
        justify-content:space-between !important;
    }
    .page-header .page-heading {
        width: 100%;
    }
    .excel-pdf-email-link span {
        color: #3A3952;
        font-size: 10px;
        line-height: 11px;
        margin-right: 3px;
    }

    .excel-pdf-email-link {
        margin-top: 10px;
    }

    .table-extra {
        flex-wrap: wrap;
        justify-content: flex-start !important;
    }



    // .table-extra .download-btns {
    //     width: 100%;
    // }

    // .table-extra span.my-spacer {
    //     display: none;
    // }

    .page-header span.my-spacer {
        display: none;
    }

    .excel-pdf-email-link span {
        padding-bottom: 8px;
    }

    .mat-mdc-button.mat-mdc-button-base,
    .mat-mdc-raised-button.mat-mdc-button-base,
    .mat-mdc-unelevated-button.mat-mdc-button-base,
    .mat-mdc-outlined-button.mat-mdc-button-base {
        padding: 5px 8px;

    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .table-extra {
        flex-wrap: wrap;

    }

    // .mat-mdc-table .mdc-data-table__header-row th{
    //     font-size: 10px;
    // }

    .excel-pdf-email-link {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    // .table-extra .sreach-input {
    //     width: 67px !important;
    // }

    .mat-mdc-button.mat-mdc-button-base,
    .mat-mdc-raised-button.mat-mdc-button-base,
    .mat-mdc-unelevated-button.mat-mdc-button-base,
    .mat-mdc-outlined-button.mat-mdc-button-base {
        padding: 4px 8px !important;
    }

}

// @media screen and (min-width: 992px) and (max-width: 1024px) {
//     .table-extra .sreach-input {
//         width: 7rem !important;
//     }
// }

.form-group span.required {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    color: #1e263e;
}




/* modal-popup */


.modal-head {
    background: $color_9;
    color: #fff;
    margin: 0px;
    font-family: "Spartan", sans-serif;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 20px !important;
    padding: 10px 20px;

}

.mat-mdc-dialog-container {
    text-align: center;
}

.modal-head img {
    height: 18px;
    margin-right: 10px;
}

mat-dialog-content.modal-content {
    text-align: center;
    padding: 0px 20px !important;
}

.modal-content {
    margin-top: 25px !important;
    margin-bottom: 10px !important;
}

.head-msg {
    color: #3A3952 !important;
    font-size: 19px !important;
    line-height: 24px !important;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 3px !important;
}

.text-popup,
.mdc-dialog__content p {
    color: #3A3952;
    font-size: 15px;
    line-height: 21px;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
    border-top: 0px !important;
    border-radius: 0px !important;
}

button.cross.mdc-button,
.mat-mdc-dialog-container .mdc-dialog__surface button.mdc-button {
    background: $color_9 !important;
    border: 1px solid $color_9;
    height: 34px !important;
    color: #fff !important;


}

.mat-mdc-dialog-container .mdc-dialog__surface {
    padding-bottom: 40px;
}

button.mat-mdc-button.mat-warn {
    --mat-mdc-button-persistent-ripple-color: #ffffff !important;
}

.mat-mdc-dialog-actions {
    justify-content: center !important;
}

.checked-mark {
    height: 50px;
}

img.truck-img1 {
    width: auto;
    height: 240px;
    margin: 10px 0px 20px;
}

.thank-u-msg {
    font-size: 15px;
    line-height: 20px;
    padding-top: 20px;
}

.modal-head {
    display: flex;
    align-items: center;
}

//*popup modal css End */

// .tab-0011 .mat-mdc-tab.mdc-tab {
//     background-color: transparent !important;
//     font-size: 12px;
//     padding: 0px;
//     height: 34px;
// }

// .tab-0011 {
//     margin-bottom: 30px;
// }

button.add-quote.mdc-button {
    margin-right: 10px !important;
}

@media screen and (min-width: 541px) and (max-width: 767px) {
    .app-footer {
        position: unset !important;
        flex-direction: row;
    }

    .app-footer .my-button-row {
        margin-bottom: 0px;
    }

    .app-footer .my-button-row button {
        padding: 0px 5px !important;
        letter-spacing: 0px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .app-footer {
        position: absolute !important;
    }

    .app-footer .my-button-row button {
        padding: 0px 5px !important;
        letter-spacing: 0px;
        margin-bottom: 5px !important;
    }
}

.form-group.full-with-margin {
    width: 100% !important;
    margin-right: 52% !important;
}

button.cancel-btn.mdc-button.mat-mdc-button {
    background: #ffffff !important;
    border: 1px solid#4D77BB !important;
    color: #4D77BB !important;
}

.dashboard_tab span.mdc-tab__text-label {
    color: #4D77BB !important;
    font-weight: 500;
}

.mdc-text-field__input[disabled],
.mat-mdc-input-element[disabled] {
    color: $color_9 !important;

}

.mat-mdc-form-field.mat-form-field-disabled {
    color: $color_9 !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--disabled span.mat-mdc-select-min-line {
    color: $color_9 !important;

}

.mat-mdc-text-field-wrapper.mdc-text-field--invalid {
    border: 0px !important;
}

/* changes in sidebar 28-Apr-2023 */

mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full {
    width: 96% !important;
    margin-left: 4% !important;
}

// .mat-sidenav-content.app-pages.content-full .mat-toolbar.mat-primary {
//     width: 96%;
// }

.my-sidenav span.mdc-list-item__content {
    display: block;
}
.mat-drawer.mat-drawer-side span.lable-txt-para {
    display: none;
}
.my-sidenav.mat-drawer-opened span.lable-txt-para {
    display: block;
}

.layout-header-logo {
    display: none !important;
}

.my-sidenav.mat-drawer-opened .layout-header-logo {
    display: block !important;
}

.my-sidenav.mat-drawer-opened span.mdc-list-item__content {
    display: block;
}



@media only screen and (max-width: 600px) {
    .my-sidenav.mat-drawer-opened {
        width: 260px !important;
    }
    mat-toolbar.mat-toolbar.my-toolbar.mat-primary.mat-toolbar-single-row {

        background: #fff !important;
    }
    mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full {
        width: 100% !important;
        margin-left: 0% !important;
    }

}

/*
@media only screen and (min-width: 601px) and (max-width: 767px) {
    .mat-toolbar.mat-primary {
        width: 70%;
    }

    .app-pages.mat-sidenav-content.mat-drawer-content {
        margin-left: 30% !important;
        width: 70% !important;
    }
    .logo-box {
        padding: 15px 12px 40px;
    }

    mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full {
        width: 92% !important;
        margin-left: 8% !important;
    }

    .mat-drawer.mat-drawer-side {
        display: block !important;
        width: 8% !important;
        margin-left: 8% !important;
        visibility: visible !important;
    }

    .my-sidenav.mat-drawer-opened {
        width: 30% !important;
        margin-left: 0% !important;
    }

    .mat-toolbar.mat-primary {
        color: $color_9 !important;
        padding: 0px 20px 0px 20px !important;
    }

    // mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full .mat-toolbar.mat-primary.topbar-fixed {
    //     width: 92%;
    // }

    .layout-header-logo img {
        width: 94px;
        margin-left: 6px;
    }
}
*/

@media only screen and (min-width: 768px) and (max-width: 900px) {
    /*
    .app-pages.mat-sidenav-content.mat-drawer-content {
        margin-left: 26% !important;
        width: 74% !important;
    }

    .mat-toolbar.mat-primary {
        width: 74%;
    }

    mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full {
        width: 92% !important;
        margin-left: 8% !important;
    }

    .mat-drawer.mat-drawer-side {
        display: block !important;
        width: 8% !important;
        margin-left: 8% !important;
        visibility: visible !important;
    }

    .my-sidenav.mat-drawer-opened {
        width: 26% !important;
        margin-left: 0% !important;
    }*/

    // mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full .mat-toolbar.mat-primary.topbar-fixed {
    //     width: 92%;
    // }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
        margin-left: 0px;
        width: 50%;
        border-radius: 50px !important;
        margin-bottom: 11px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
        flex-wrap: wrap;
        background: transparent;
    }

}

@media only screen and (min-width: 901px) and (max-width: 992px) {
    // .app-pages.mat-sidenav-content.mat-drawer-content {
    //     margin-left: 23% !important;
    //     width: 77% !important;
    // }

    // .mat-toolbar.mat-primary {
    //     width: 77%;
    // }

    // mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full {
    //     width: 93% !important;
    //     margin-left: 7% !important;
    // }

    // .mat-drawer.mat-drawer-side {
    //     display: block !important;
    //     width: 7% !important;
    //     margin-left: 7% !important;
    //     visibility: visible !important;
    // }

    // .my-sidenav.mat-drawer-opened {
    //     width: 23% !important;
    //     margin-left: 0% !important;
    // }

    // mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full .mat-toolbar.mat-primary.topbar-fixed {
    //     width: 93%;
    // }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
        margin-left: 0px;
        width: 50%;
        border-radius: 50px !important;
        margin-bottom: 11px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
        flex-wrap: wrap;
        background: transparent;
    }

}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    /*
    .app-pages.mat-sidenav-content.mat-drawer-content {
        margin-left: 25% !important;
        width: 75% !important;
    }

    .mat-toolbar.mat-primary {
        width: 75%;
    }

    mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full {
        width: 93% !important;
        margin-left: 7% !important;
    }

    .mat-drawer.mat-drawer-side {
        display: block !important;
        width: 7% !important;
        margin-left: 7% !important;
        visibility: visible !important;
    }

    .my-sidenav.mat-drawer-opened {
        width: 25% !important;
        margin-left: 0% !important;
    }
    */

    // mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full .mat-toolbar.mat-primary.topbar-fixed {
    //     width: 93%;
    // }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
        margin-left: 0px;
        width: 50%;
        border-radius: 50px !important;
        margin-bottom: 11px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
        flex-wrap: wrap;
        background: transparent;
    }

}

@media only screen and (min-width: 1025px) and (max-width: 1100px) {
    // .app-pages.mat-sidenav-content.mat-drawer-content {
    //     margin-left: 20% !important;
    //     width: 80% !important;
    // }

    // .mat-toolbar.mat-primary {
    //     width: 80%;
    // }
    // .mat-drawer.mat-drawer-side {
    //     display: block !important;
    //     width: 7% !important;
    //     margin-left: 7% !important;
    // }

    // mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full {
    //     width: 93% !important;
    //     margin-left: 7% !important;
    // }

    // mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full .mat-toolbar.mat-primary.topbar-fixed {
    //     width: 93%;
    // }

    // .my-sidenav.mat-drawer-opened {
    //     width: 20% !important;
    //     margin-left: 0% !important;
    // }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
        margin-left: 0px;
        width: 33.33%;
        border-radius: 50px !important;
        margin-bottom: 11px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
        flex-wrap: wrap;
        background: transparent;
    }

}

@media only screen and (min-width: 1101px) and (max-width: 1140px) {
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
        margin-left: 0px;
        width: 33.33%;
        border-radius: 50px !important;
        margin-bottom: 11px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
        flex-wrap: wrap;
        background: transparent;
    }
}

/* End changes in sidebar 28-Apr-2023 */

.my-sidenav mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item .menuIcon {
    margin: 0px;
    margin: 3px 8px 5px 0px;
    height: auto !important;
    width: 16px !important;
}

.delete-btn button.mdc-button.mat-mdc-button.mat-primary {
    background: red !important;
    border: 1px solid#ff0000 !important;
}

.delete-btn button.mdc-button.mat-mdc-button.mat-warn {
    background: #ffffff !important;
    color: red !important;
    border: 1px solid#ff0000 !important;
}

.success-snack div {
    background-color: green !important;
    border-radius: 4px !important;
}

.success-snack div button.mat-mdc-snack-bar-action.mdc-snackbar__action.mdc-button {
    background: #fff !important;
    color: green !important;
    border-radius: 2px;
    font-size: 13px;
    line-height: 16px;
    width: 59px !important;
}

.success-snack div .mat-mdc-snack-bar-label.mdc-snackbar__label {
    font-family: "SpartanRegular", sans-serif;
    color: #fff;
    margin: 0px;
    font-size: 15px;
    line-height: 18px;
    padding-right: 50px;
}


.error-snack div {
    background-color: red !important;
    border-radius: 4px !important;
}

.error-snack div .mat-mdc-snack-bar-label.mdc-snackbar__label {
    font-family: "SpartanRegular", sans-serif;
    color: #fff;
    margin: 0px;
    font-size: 15px;
    line-height: 18px;
    padding-right: 50px;
}

.error-snack button {
    color: red !important;
    background-color: #fff !important;
    border-radius: 2px;
    font-size: 13px;
    line-height: 16px;
    width: 59px !important;
}

.view-form-field .mat-mdc-text-field-wrapper {
    background: $color_11;
}

.view-form-field .file-field {
    background: $color_11;
}

mat-sidenav .menu-list-item mat-icon.mat-mdc-list-item-icon {
    font-size: 17px !important;
    line-height: 17px;
    vertical-align: middle;
}

.mat-icon.last-step1 {
    position: absolute;
    right: 0px;
    top: 0px;
    background: #fafafa !important;
    height: 40px !important;
    width: 40px !important;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 50px;
    line-height: 44px !important;
    text-align: center;
    margin-left: -22px;
    z-index: 2;
    line-height: 34px !important;
}

.mat-horizontal-stepper-header-container:after {
    content: none;
}

mat-step-header[ng-reflect-selected=false],
mat-step-header[ng-reflect-active=true] {
    border-radius: 50px 50px 50px 50px !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
    margin-left: -31px !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
    padding: 0px 50px 0px 10px !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
    height: 40px !important;
}

.mat-horizontal-stepper-header-container .mat-step-icon.mat-step-icon-state-error {
    height: 40px;
    width: 40px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.mat-horizontal-stepper-header-container .mat-step-icon-state-error .mat-icon {
    color: red;
}

.mat-horizontal-stepper-header-container {
    padding: 0px 0px 0px 31px !important;
}

@media only screen and (max-width: 767px) {
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
        margin-left: 0px !important;
    }
    // .mat-mdc-table .mdc-data-table__header-row th{
    //     font-size: 10px;
    // }
    .mat-horizontal-stepper-header-container {
        padding: 0px 0px 0px 0px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .page-header .page-heading {
        width: 40%;
    }
}

.date-not-mandotory .mat-mdc-form-field-infix {
    height: 0px;
}

.date-not-mandotory mat-icon.mat-icon.notranslate.clear-icon {
    position: absolute;
    float: right;
    top: 1px;
    right: 5px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
}

@media only screen and (max-width: 400px) {

    .Home-section,
    .Mail-section,
    .mdc-button.language-change,
    .static-section {
        padding-right: 12px !important;
    }
}

.resource-image {
    height: 40px;
}

.resource-image1 {
    height: 37px;
    margin-left: 12px;
    margin-bottom: -9px;
}

@media only screen and (max-width: 767px) {
    .resource-image1 {
        height: 28px;
        margin-left: 4px;
        margin-bottom: -7px;
    }

    .buttons-trip-boards {
        flex-wrap: wrap;
    }

    .buttons-trip-boards button.mdc-button {
        margin-bottom: 10px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .resource-image1 {
        height: 28px;
        margin-left: 4px;
        margin-bottom: -7px;
    }

    .page-header .page-heading {
        width: 100%;
    }

    .page-header h1 {
        margin: 0px;
        font-size: 16px;
    }

    .page-header button {
        font-size: 10px !important;
        padding: 5px !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .page-header button {
        font-size: 13px !important;
        padding: 5px 7px !important;
    }

    .page-header .page-heading {
        width: 32%;
    }

    .resource-image1 {
        height: 28px;
        margin-left: 4px;
        margin-bottom: -7px;
    }
}

mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item .lable-txt .lable-txt-para {
    transition: transform 0.9s;
}

mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item:hover .lable-txt .lable-txt-para{
    transform: scale(1.066);
    padding-left: 4px;
    background: none !important;
}

.mat-mdc-list-item:hover {
    background: none !important;
}

.menuIcon.blue-icon {
    display: none;
}

.menuIcon.green-icon {
    display: none;
}

mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item:hover .menuIcon.blue-icon {
    display: block;
}

mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item:hover .menuIcon.green-icon {
    display: none;
}

mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item.active .menuIcon.blue-icon {
    display: none;
}

mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item.active .menuIcon.green-icon {
    display: block;
}

mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item:hover .menuIcon.white_icon,
mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item.active .menuIcon.white_icon {
    display: none !important;
}

mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item.active .mdc-button {
    transition: transform 1s;
}

.mdc-button:hover {
    transform: scale(1.039);
}

mat-toolbar.mat-toolbar.my-toolbar.mat-primary.mat-toolbar-single-row {
    border-bottom: 1px solid #dcd6d6 !important;
}

.mat-horizontal-stepper-content .form-group-btn button {
    margin-bottom: 10px !important;
}

button.contact-driver-button.mdc-button {
    color: #fff !important;
}

h3.sub-hs-head {
    margin-left: 1%;
    width: 98%;
    font-family: "SpartanRegular", sans-serif;
    color: $color_9;
    margin: 0px 0px 15px 1%;
}

.add_more_button {
    display: flex;
    width: 99%;
    justify-content: flex-end;
    padding: 10px 0px;
}

.add_more_button button .mdc-button__label {
    color: #fff;
    font-size: 12px;
}

.form-group.checkbox {
    width: 100% !important;
    border: 1px solid#958585;
    padding: 20px;
    border-radius: 4px;
    margin: 0px 0px 10px !important;
}

section.example-section.checkbox-0011 mat-checkbox {
    width: 25%;
}

section.example-section.checkbox-0022 mat-checkbox {
    width: 20%;
}

.add_more_button .mat-primary.mat-mdc-button-base {
    background: #292e5a !important;
    border: 1px solid #292e5a;
    height: 34px !important;
    font-size: 12px;
    padding: 0px 8px;
}

@media only screen and (max-width: 500px) {
    section.example-section.checkbox-0011 mat-checkbox {
        width: 100%;
    }

    section.example-section.checkbox-0022 mat-checkbox {
        width: 100%;
    }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
    section.example-section.checkbox-0011 mat-checkbox {
        width: 50%;
    }

    section.example-section.checkbox-0022 mat-checkbox {
        width: 50%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    section.example-section.checkbox-0011 mat-checkbox {
        width: 33.33%;
    }

    section.example-section.checkbox-0022 mat-checkbox {
        width: 33.33%;
    }
}

// .filter_search {
//     display: flex;
//     align-items: center;
//     flex-wrap: wrap;
// }

// .filter_search .serch-table {
//     margin-right: 3px;
//     margin-top: 5px;
//     margin-bottom: 5px !important;
//     display: flex;
//     align-items: center;
// }

// .filter_search .serch-table input {
//     height: 20px !important;
//     padding: 0px 8px !important;
//     font-size: 10px;
//     width: 60px;
//     margin-left: 2px;
// }

// .filter_search .serch-table .mat-mdc-form-field {
//     height: 20px !important;
//     padding: 0px 0px !important;
//     font-size: 10px;
//     width: 70px;
//     margin-left: 2px;
// }

// .filter_search span.mat-mdc-select-min-line {
//     font-size: 8px;
// }

// .serch-table mat-label {
//     color: $color_9;
//     font-size: 10px;
//     line-height: 10px;
//     margin-left: 2px;
// }

// .filter_search .serch-table .mat-mdc-text-field-wrapper {
//     height: 20px !important;
//     padding: 0px 4px !important;
//     font-size: 10px;
//     width: 70px;
// }

// .filter_search .mat-mdc-select-trigger {
//     height: 20px !important;
//     top: -4px !important;
// }

// .filter_search .mat-mdc-icon-button svg {
//     height: 12px;
//     width: 12px;
// }

// .filter_search .mat-mdc-icon-button.mat-mdc-button-base {
//     width: 10px !important;
//     height: 10px !important;
//     padding: 0px !important;
//     margin-top: -22px !important;
// }

// .filter_search .serch-table input.mat-datepicker-input {
//     padding: 0px 0px !important;
//     font-size: 7px !important;
//     position: relative;
//     top: -4px !important;
// }

.filter_search-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

button.all-resources-status-btn.mdc-button {
    background: #ffffff !important;
    border: 1px solid #4D77BB !important;
    color: $color_9 !important;
}
// .tabs-section .mat-mdc-tab.mdc-tab:after {
//     content: "";
//     background: #7683ac;
//     height: 41px;
//     width: 1.4px;
//     margin: 0px 20px;
//     margin-top: 6px;
// }
// .tabs-section .mat-mdc-tab.mdc-tab  .mdc-tab-indicator {
//     left: -18% !important;
// }
// .tabs-section .mat-mdc-tab.mdc-tab span.mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
//     width: 80% !important;
// }
// .tabs-section .mat-mdc-tab.mdc-tab:last-child::after {
//     visibility: hidden;
// }

// @media screen and (min-width: 768px) and (max-width: 991px) {
//     .filter_search .serch-table input {
//         width: 8em !important;
//     }

//     .page-header .page-heading {
//         width: 38%;
//     }
//     // .tabs-section .mat-mdc-tab.mdc-tab {
//     //     margin: 0px 4px 0px !important;
//     // }
//     .page-header .page-heading {
//         margin-bottom: 11px;
//     }
// }

// @media screen and (min-width: 992px) and (max-width: 1024px) {
//     .filter_search .serch-table input {
//         width: 7em !important;
//     }
// }

// @media screen and (min-width: 1025px) and (max-width: 1140px) {
//     .filter_search .serch-table input {
//         width: 9em !important;
//     }

//     .page-heading {
//         width: 32%;
//     }
// }

// .filter_search1 {
//     display: flex;
//     justify-content: flex-start;
//     width: 80%;
// }

// .filter_search1 .serch-table1 {
//     margin-right: 1%;
//     margin-bottom: 6px;
//     width: 19%;
// }

// .filter_search1 .serch-table1 .mat-mdc-text-field-wrapper {
//     font-size: 14px !important;
//     height: 34px !important;
//     line-height: 24px !important;
// }

// .filter_search1 .serch-table1 .mat-mdc-select-trigger {
//     top: -3px
// }

// .filter_search1 .serch-table1 span.mat-mdc-select-min-line {
//     font-size: 13px;
//     line-height: 18px;
// }

// .filter_search1 .serch-table1 .mat-mdc-icon-button.mat-mdc-button-base {
//     margin-top: -1px !important;
// }

// .filter_search1 .serch-table1 .mat-mdc-form-field-subscript-wrapper {
//     height: 0px !important;
// }

// .all-resources-tab .mat-mdc-tab.mdc-tab {
//     background: #fff;
//     font-size: 6px;
//     height: 30px;
//     margin: 0px 5px 20px;
//     padding: 0px !important;
//     border: 2px solid #4D77BB;
//     border-radius: 4px;
//     max-width: 140px;
// }

// .all-resources-tab .mat-mdc-tab-labels {
//     justify-content: flex-end;
// }

// .all-resources-tab span.mdc-tab-indicator span {
//     border: 0px solid #4D77BB !important;
//     background: #4D77BB;
// }

// .all-resources-tab .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active {
//     background: #4D77BB;

// }

// .all-resources-tab .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active span.mdc-tab__text-label {
//     color: #fff;
// }

// .all-resources-tab span.mdc-tab__text-label {
//     font-size: 13px;
// }

// .all-resources-tab .mat-mdc-dialog-surface.mdc-dialog__surface {
//     padding-bottom: 20px;
// }

.serch-table1 mat-label {
    font-size: 11px;
    line-height: 20px;
}

.table-extra .serch-table1 .sreach-input {
    padding: 0px 0px !important;

}

@media only screen and (min-width: 1401px) and (max-width: 1500px) {
    // .mat-drawer.mat-drawer-side {
    //     width: 4% !important;
    //     margin-left: 4% !important;
    // }

    // .my-sidenav.mat-drawer-opened {
    //     width: 16% !important;
    //     margin-left: 0% !important;
    // }

    // .app-pages.mat-sidenav-content.mat-drawer-content {
    //     margin-left: 16% !important;
    //     width: 84% !important;
    // }

    // .mat-toolbar.mat-primary {
    //     width: 84%;
    // }

    // mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full {
    //     width: 96% !important;
    //     margin-left: 4% !important;
    // }

    // .mat-sidenav-content.app-pages.content-full .mat-toolbar.mat-primary {
    //     width: 84%;
    // }

    // mat-list-item.mat-mdc-list-item.mdc-list-item.menu-list-item .nav-item .lable-txt {
    //     font-size: 11px !important;
    // }
}

@media only screen and (min-width: 1501px) and (max-width: 1780px) {
    // .mat-drawer.mat-drawer-side {
    //     width: 4% !important;
    //     margin-left: 4% !important;
    // }

    // .my-sidenav.mat-drawer-opened {
    //     width: 14% !important;
    //     margin-left: 0% !important;
    // }

    // .app-pages.mat-sidenav-content.mat-drawer-content {
    //     margin-left: 14% !important;
    //     width: 86% !important;
    // }

    // .mat-toolbar.mat-primary {
    //     width: 86%;
    // }

    // mat-sidenav-content.mat-drawer-content.mat-sidenav-content.app-pages.content-full {
    //     width: 96% !important;
    //     margin-left: 4% !important;
    // }

    // .mat-sidenav-content.app-pages.content-full .mat-toolbar.mat-primary {
    //     width: 96%;
    // }
}

.mat-mdc-table .mdc-data-table__header-row th.serial-num {
    width: 50px;
}

.accordion-001 .mat-expansion-indicator::after {
    color: $color_9 !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background: #3f51b5 !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: #3f51b5 !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__shadow {
    box-shadow: none !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple {
    height: 0px !important;
    width: 0px !important;
}

.example-action-buttons.expand-all-button button {
    margin: 0px 5px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
    height: var(--mdc-switch-track-height, 18px) !important;
    border-radius: 25px !important;
}

.mat-mdc-table td {
    white-space: nowrap;
}

.pagination-resources {
    position: relative;
}

@media only screen and (max-width: 767px)  {
.mat-mdc-paginator-range-actions {
        justify-content: flex-end;
}
.mat-mdc-paginator-range-label {
    margin: 0px 0px 0px 0px;
}
.mat-mdc-paginator-range-actions {
    width: 100%;
}
}
.my-sidenav.mat-drawer-opened span.mdc-list-item__content span.mdc-list-item__primary-text {
    color: #fff;
}
.form-container .form-group {
    width: 31.33% !important;
    margin: 0px 1% !important;
}
.page-header h1 {
    text-transform: capitalize;
}
span.mdc-button__label {
    text-transform: capitalize;
}
.serch-table input {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 8px;
    background-color: transparent;
    margin-left: 3px;
    width: 11rem;
    height: 30px;
    padding: 0px 10px !important;
}
.pp-header .img img {
    height: 30px;
}

.page-header button.back-button-head.mdc-button {
    background: $color_9 !important;
    border: 1px solid $color_9 !important;
    color: #fff !important;
    margin-left: 10px !important;
}
.link_hover {
    text-decoration: none;
}
.link_hover:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
}
.form-group-btn {
    border-top: 1.6px solid #b0b1b4;
    margin-top: 5px;

}
button.delete-btn-cancel.mdc-button.mat-mdc-button{
    background: red !important;
    border: 1px solid #ff0000 !important;
}
.mat-mdc-raised-button:disabled {
    color: #7d7a7a !important;
}

.mat-mdc-unelevated-button[disabled][disabled] {
    --mdc-filled-button-disabled-container-color: #7d7a7a !important;
    --mdc-filled-button-disabled-label-text-color: #7d7a7a !important;
    --mdc-filled-button-container-color: #7d7a7a !important;
    --mdc-filled-button-label-text-color: #7d7a7a !important;
}
.menu-icon66 mat-icon.mat-icon {
    color: #CF232A;
}
.profile-menu .mat-icon img.toggle-005 {
    height: 15px !important;
    width: auto !important;
}
.mat-mdc-table .mdc-data-table__header-row th .mat-sort-header-container{
    justify-content: center;
}
.mat-mdc-table td, .mat-mdc-table .mdc-data-table__header-row th {
    text-align: center;
}
.mat-mdc-table .mdc-data-table__header-row th .mat-sort-header-arrow {
    opacity: 1 !important;    transform: translateY(0%) !important;    margin-left: 1px; margin-top: -1px;
}
.mat-sort-header-stem {
    height: 0px;
}
.mat-mdc-table .mdc-data-table__header-row th .mat-sort-header-arrow {
    visibility: hidden;
}

button.cross-popup1 {
    border: 0px  !important;
    background: transparent;
    color: #fff;
    font-size: 20px;
    padding: 0px;
    outline: none !important;
    cursor: pointer;
}
.cross-popup.modal-head{
padding: 7px 20px;
}
table.dataTable thead>tr>th.sorting{
position: relative;
}
.mat-mdc-table .mdc-data-table__header-row th .mat-sort-header-content:after {
    top: 50%;
    content: "▼"/"";
    position: absolute;
    display: block;
    opacity: .125;
    right: 10px;
    line-height: 9px;
    font-size: .8em;
}
.mat-mdc-table .mdc-data-table__header-row th .mat-sort-header-content:before{
    bottom: 50%;
    content: "▲"/"";
    position: absolute;
    display: block;
    opacity: .125;
    right: 10px;
    line-height: 9px;
    font-size: .8em;
}


// .mat-mdc-table .mdc-data-table__header-row th[aria-sort="ascending"] .mat-sort-header-content:before{
//     opacity: 1;
// }
// .mat-mdc-table .mdc-data-table__header-row th[aria-sort="descending"] .mat-sort-header-content:after{
//     opacity: 1;
// }

// .tabs-section .mat-mdc-tab.mdc-tab {
//     margin: 0px 0px 0px 0px;
//     color: #1e263e !important;
//     margin-top: 0px;
//     font-size: 12px;
//     height: 30px !important;
//     background: #fff;
//     padding: 0px 0px !important;
//     font-weight: 700;
// }
// .tabs-section mat-tab-header.mat-mdc-tab-header {
//     background: #fff;
//     box-shadow: 2px 2px 12px #ccc;
//     border-radius: 3px;
//     padding: 10px 1rem 16px;
//     margin-bottom: 20px;
// }
// .tabs-section .mat-mdc-tab-body-wrapper{
//     background: #fff;
//     box-shadow: 2px 2px 12px #ccc;
//     border-radius: 3px;
//     padding: 10px 1rem 16px;
//     margin-bottom: 30px;
// }
// .tabs-section .mat-mdc-tab-labels {
//     float: left;
// }

// .mdc-tab:hover .mdc-tab-indicator{
//     background-color: transparent !important;
// }
// .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
//     color: #6D6D6D;
// }

// .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
//     color: #222222;
// }
// .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
//     border-color: #CF232A;
// }


//for filters

.filter-box {
    display: flex;
    align-items: flex-end;
    padding: 2px 3px 5px;
    justify-content: flex-start;

  }
  .filter-box .form-group {
    width: 33.33% !important;

  }
  .filter-box  .buttons-filter {
    width: 33.33%;
    display: flex;
  }
  .filter-box  .buttons-filter button{
    margin-right: 10px !important;
  }
  .filter-box select#type-select, .filter-box .mat-mdc-unelevated-button.mat-mdc-button-base {
    height: 40px !important;
    padding: 3px;
    font-weight:bold;

  }
  .panel-body{
    padding: 20px 15px 10px 15px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 4px;
    height: auto;
  }
//   .filter-box .form-group .mat-mdc-select {
//     width: 90% !important;
//   }

  .filter-box .form-group mat-form-field {
    width: 90% !important;
  }

  .filter{
    background: #fff none repeat scroll 0 0;
    height: auto;
    font-size: 1rem;
    margin-left: 1rem;
    padding: 3px 10px;
    border-radius: 2px;
    position: absolute;
    text-transform: capitalize;
    margin-top: -40px;
    border: 1px solid #ccc;
    font-weight: 600;
  }

  @media only screen and (max-width: 767px)  {
    .filter-box .form-group {
      width: 100%;
      margin-right: 0px;
    }
    .filter-box {
      flex-direction: column;
    }
    .filter-box .buttons-filter {
      margin-top: 10px;
    }

  }

  .filter-box.rate-filter-box .mat-mdc-select {
    margin: 0px 16px 0px 10px !important;
    padding: 3px !important;
    border: 1px solid#000;
  }
  .filter-box.rate-filter-box .form-group {
    margin-right: 0px !important;
  }
  .filter-box.rate-filter-box {
    width: 96%;
    align-items: center;
    justify-content: center;
  }
  .filter-box.rate-filter-box .buttons-filter {
    margin-left: -15px;
  }
  .filter-box.rate-filter-box .mat-mdc-unelevated-button.mat-mdc-button-base{
    height: 34px !important;
  }

  .filter-box label.col-form-label {
    font-weight: 600;
    line-height: 24px !important;
    font-size: 13px !important;
  }
  @media only screen and (max-width: 600px)  {
    .filter-box .form-group, .filter-box .buttons-filter {
      width: 100% !important;
    }
    .filter-box .form-group mat-form-field {
      width: 100% !important;
      margin-bottom: 10px;
    }

  }
  @media only screen and (min-width: 601px) and (max-width: 767px)  {
    .filter-box .form-group, .filter-box .buttons-filter {
      width: 100% !important;
    }
    .filter-box .form-group mat-form-field {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }

//for filters
